<template>
  <!-- <div class="main"> -->
  <div :class="!context.debug.setupTasti ? 'main' : ''">
    <template v-if="!context.debug.setupTasti">
      <gruppo v-for="(gr, index) in gruppi" :key="index"
        :context="context"
        :zona="gr.zona"
        :tasti="isTastoVisible(gr)"
        @send-event="sendEvent"
      >
      </gruppo>
      <!-- :tasti="gr.tasti.filter(x => !x.page || x.page === context.pageList[gr.zona])" -->
    </template>
    <template v-else>
      <configuratore-tasti :context="context" :gruppiTasti="gruppi" @send-event="sendEvent" :parametriLayout="parametriLayout">
      </configuratore-tasti>
      <!-- <gruppo-edit v-for="(gr, index) in gruppi" :key="index"
        :context="context"
        :zona="gr.zona"
        :tasti="gr.tasti"
        @send-event="sendEvent"
      >
      </gruppo-edit> -->
    </template>
    <!-- <scontrino-semplificato :context="context" @send-event="sendEvent"/> -->
    <scontrino-semplificato v-if="!context.debug.setupTasti" :context="context" @send-event="sendEvent"/>
    <keyEvent :context="context" :resetBuffer="context.resetBuffer" :displayValue="context.displayValue" @send-event="sendEvent"/>
    <modale :context="context" @send-event="sendEvent" @send-event-delay="sendEventWithDelay"/>
    <popup :context="context" @send-event="sendEvent"/>
    <errore-stampante :errore="context.erroreStampante" @send-event="sendEvent"/>
    <toast-generico :message="context.messaggioToast" :confirm="context.confirmToast" :confirmObj="context.confirmToastObj"  @send-event="sendEvent"/>
    <display :context="context" />
    <accesso :context="context" @send-event="sendEvent" />
  </div>
</template>

<script>
/* eslint-disable */
  import { interpret } from 'xstate';
  import { dashboardMachine } from '@/machines/dashboardMachine.js';
  // import loggerDNA from '@/services/loggerServiceDNA.js'
  import logger from '@/services/loggerService.js'

  import Gruppo from '@/components/gruppoTasti/Gruppo.vue'
  // import GruppoEdit from '@/components/gruppoTasti/editor/GruppoEdit.vue'
  import ConfiguratoreTasti from '@/components/gruppoTasti/editorNew/ConfiguratoreTasti.vue'
  // import Schermo from '@/components/schermo/Schermo.vue'
  import ScontrinoSemplificato from '@/components/scontrino/ScontrinoSemplificato.vue'

  import KeyEvent from '@/components/keyEvent/KeyEvent.vue'
  import Modale from '@/components/modale/Modale.vue'
  import Popup from '@/components/popup/Popup.vue'
  import ErroreStampante from '@/components/erroreStampante/index.js'
  import ToastGenerico from '@/components/toastGenerico/index.js'
  import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
  import PopupListResult from '@/components/popup/ListResult'
  import Display from '@/components/display'
  import Accesso from '@/components/accesso'

  import configurazioni from '@/services/configurazioniService.js'
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { insomnia } from '@/mixins/insomnia.js'

  export default {
    name: 'layout01',
    components: {
      Keypress: () => import('vue-keypress'),
      Gruppo,
      // GruppoEdit,
      ConfiguratoreTasti,
      ScontrinoSemplificato,
      KeyEvent,
      Modale,
      Popup,
      ErroreStampante,
      ToastGenerico,
      SimplePopupKeyboard,
      PopupListResult,
      Display,
      Accesso
    },
    mixins: [verificaLicenza, insomnia],
    created() {
      // Start service on component creation
      this.displayService
        .onTransition(state => {
          // console.log('change state: ', state)
          console.log('change state: ', `${JSON.stringify(state.value)}  TYPE: ${state.event.type}    VALUE: ${JSON.stringify(state.event.value || {})}`, state)
          // Update the current state component data property with the next state
          this.current = state;
          // Update the context component data property with the updated context
          this.context = state.context;

          // TODO: Rimettere per log operazioni
          if (state.context.impostazioni && state.context.impostazioni.debug && state.context.impostazioni.debug.log_state_change) {
            logger.log(`${JSON.stringify(state.value)}  TYPE: ${state.event.type}    VALUE: ${JSON.stringify(state.event.value || {})}`, 'layout', 'info')
          }

          if (state.context.reloadPage) {
            state.context.reloadPage = false
            this.$router.go(0)
          }
          if (state.context.importDati) {
            state.context.importDati = false
            this.$router.push('/import')
          }
          if (this.timeoutTimerID) {
            this.setupTimer()
          }          
        })
        .start()

      setTimeout(async () => {
        await this.cicleSyncProcess()
        const status = await this.getProcessStatus()
        this.$store.commit('SET_SERVICE_STATUS', status)
      }, 1000)
    },
    data() {
      return {
        // Interpret the machine and store it in data
        displayService: interpret(dashboardMachine),
        // Start with the machine's initial state
        current: dashboardMachine.initialState,
        // Start with the machine's initial context
        context: dashboardMachine.context,
        configGruppi: [],
        parametriLayout: {},
        impostazioni: null,
        logger: null,
        timeoutTimerID: null,
        logOutTimeout: 0        
      }
    },
    async mounted() {
      const layoutTasti = this.$store.getters.layoutTasti // 'layout_01'
      await this.gestioneToken()
      this.context.runMode = await this.debugMode()
      this.context.scontrinoTick++
      this.context.layoutTasti = layoutTasti

      var result = await configurazioni.getConfigurazione('gruppi_tasti', layoutTasti)
      this.configGruppi = result.gruppi || []
      var result = await configurazioni.getConfigurazione('gruppi_tasti', 'parametri')
      this.parametriLayout = result && result.length === 0 ? {} : result
      result = await configurazioni.getConfigurazione('display_generale')
      this.context.impostazioni = result || {}
      // Verifica errore stampante --> TODO: Mettere periodico ?
      setTimeout(() => {
        this.context.listino = this.context.impostazioni.listini.base || 'L1'
        if (!this.$store.getters['initialPrinterState'] && !this.context.impostazioni.stampante.stampa_disabilitata) {
          this.displayService.send('GET_INITIAL_PRINTER_STATUS')
          this.$store.commit('SET_INITIAL_PRINTER_STATUS_FLAG')
        }
      }, 200)
      const logoutMinuti = this.context.impostazioni.operatori.logoutMinuti || 0
      if (logoutMinuti) {
        this.logOutTimeout = logoutMinuti * 60 * 1000
        this.startTimer()
      }      
    },
    methods: {
      startTimer() {
        this.timeoutTimerID = window.setTimeout(this.logoutUser, this.logOutTimeout)
      },
      setupTimer() {
        window.clearTimeout(this.timeoutTimerID)
        this.startTimer()
      },
      logoutUser() {
        window.clearTimeout(this.timeoutTimerID)
        if (this.context.operatore) {
          const event = {
            type: 'SELEZIONA_OPERATORE',
            value: {
              tipo: 'servizio',
              item: null
            }
          }
          this.sendEvent(event)
        }
      },      
      isTastoVisible(gr) {
        const pl = parseInt(this.context.pageList[gr.zona])
        return gr.tasti.filter(x => !x.page || (x.page && Array.isArray(x.page) && x.page.includes(pl)) || x.page && !Array.isArray(x.page) && x.page === pl)
        // return gr.tasti.filter(x => !x.page || x.page === context.pageList[gr.zona])
      },
      // Send events to the service
      sendEvent(event) {
        this.displayService.send(event)
      },
      sendEventWithDelay(event) {
        var self = this
        var delay = event.delay
        delete event.delay
        setTimeout(() => {
          self.displayService.send(event)
        }, delay);
      }
    },
    computed: {
      gruppi() {
        return this.configGruppi.filter(x => !x.zona.startsWith('funzioni'))
      }
    }
}
</script>

<style lang="scss">
  @import "./layout.scss";
</style>
